import React, { useMemo } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import Text from '../../UI/Text';
import FormikTextField from 'src/components/UI/Formik/FormikTextField';
import Onboarding from 'src/components/UI/Onboarding';
import Button from 'src/components/UI/Button';
import Display from 'src/components/UI/Display';
// import Link from 'next/link';
import { useLocalizedYup } from '../../../lib/yup';
import Spinner from '../../UI/Spinner';
import Animations from '../../UI/Animations';
import Spacing from '../../UI/Spacing';
import ErrorCard from '../../UI/ErrorCard';

export type LoginFormUser = { email: string; password: string };

type LoginTemplateTypes = {
  doLoginUser: (values: LoginFormUser, formikHelpers: FormikHelpers<LoginFormUser>) => void;
  redirectParams?: string;
  exportConnectHomeUri?: string;
  caExportConnectHomeUri?: string;
  googleTagManagerId?: string;
};

const LoginTemplate: React.FC<LoginTemplateTypes> = ({ doLoginUser, ...props }) => {
  const intl = useIntl();
  const yup = useLocalizedYup();

  const loginSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email()
          .label(intl.formatMessage({ id: 'login.email' }))
          .required(),
        password: yup
          .string()
          .matches(
            // At least 8, special characters, at least one number, at least one lowercase character, at least one uppercase character
            // /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,

            // At least 8, special characters, at least one number, characters in any casing
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-zA-Z]){1}).*$/,
            intl.formatMessage({ id: 'signup.passwordRequirement' })
          )
          .label(intl.formatMessage({ id: 'login.password' }))
          .required()
      }),
    [yup, intl]
  );

  return (
    <Onboarding
      exportConnectHomeUri={props.exportConnectHomeUri}
      caExportConnectHomeUri={props.caExportConnectHomeUri}
      googleTagManagerId={props.googleTagManagerId}
    >
      <Text.H3 color="light" fontSize="px24" fontWeight="bold" marginBottom="px24">
        <FormattedMessage id="login.title" />
      </Text.H3>

      <Formik initialValues={{ email: '', password: '' }} onSubmit={doLoginUser} validationSchema={loginSchema}>
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <div>
              {errors['form'] && (
                <Animations.FadeUp>
                  <Spacing marginBottom="px32">
                    <ErrorCard>
                      <FormattedMessage id={errors['form']} />
                    </ErrorCard>
                  </Spacing>
                </Animations.FadeUp>
              )}
            </div>

            <Text.Paragraph color="light" marginTop="px14" marginBottom="px8" fontSize="px14" fontWeight="bold">
              <FormattedMessage id="login.email" />
            </Text.Paragraph>
            <FormikTextField type="email" name="email" />

            <Text.Paragraph color="light" marginTop="px14" marginBottom="px8" fontSize="px14" fontWeight="bold">
              <FormattedMessage id="login.password" />
            </Text.Paragraph>
            <FormikTextField type="password" name="password" />

            <Display
              display="flex"
              marginTop="px18"
              marginBottom="px32"
              alignItems="center"
              gapHorizontal
              justifyContent="space-between"
            >
              <a href="/password">
                <Text.Paragraph color="light" fontSize="px14">
                  <FormattedMessage id="login.forgotPassword" />
                </Text.Paragraph>
              </a>

              <Button.Small
                variant="light"
                type="submit"
                width="halfWidth"
                disabled={
                  isSubmitting ||
                  (!!errors['email'] && !!touched['email']) ||
                  (!!errors['password'] && !!touched['password'])
                }
              >
                {isSubmitting ? <Spinner.Default color="paragraph" /> : <FormattedMessage id="login.loginButton" />}
              </Button.Small>
            </Display>
          </Form>
        )}
      </Formik>

      {/* TEMPORARILY REMOVE THIS ITEM TO AVOID NEW SIGNUPS https://app.clickup.com/t/1t96pp0
      <Text.Paragraph color="light" marginBottom="px12" fontSize="px14">
        <FormattedMessage
          id="login.goToSignup"
          values={{
            link: (msg: string) => (
              <Link href={redirectParams ? `/signup?${redirectParams}` : '/signup'} passHref>
                <Text.Link fontWeight="bold" color="light">
                  {msg}
                </Text.Link>
              </Link>
            )
          }}
        />
      </Text.Paragraph> */}
    </Onboarding>
  );
};

export default LoginTemplate;
