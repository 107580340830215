import { FormikHelpers } from 'formik';
import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next';
import React, { useCallback, useMemo } from 'react';
import LoginTemplate, { LoginFormUser } from 'src/components/Templates/Login';
import { useLogin, useUser } from '../src/next/lib/hooks';
import { getLocaleIdFromFetchError } from '../src/utils/exceptionUtils';
import { findByClientId, findNonManagementClientsHomeUri } from '../src/api/models/storeFront';
import env from '@env';
import logging from 'src/utils/loggingUtils';
import { ParsedUrlQuery } from 'querystring';
import { areAuthorizationParamsComplete, buildURLSearchParams } from 'src/utils/urlUtils';

type LoginContainerProps = {
  defaultStoreFrontLoginUri?: string;
  query: ParsedUrlQuery;
  exportConnectHomeUri?: string;
  caExportConnectHomeUri?: string;
  googleTagManagerId?: string;
};

type LoginProps = InferGetServerSidePropsType<typeof getServerSideProps>;

const LoginContainer: NextPage<LoginProps> = ({ defaultStoreFrontLoginUri, query, ...props }) => {
  const [redirectParams, isAuthorizationRedirect] = useMemo(() => {
    return [buildURLSearchParams(query).toString(), areAuthorizationParamsComplete(query)];
  }, [query]);

  // If we have valid authorization params, redirect to authorization, if not redirect user to login route of default storefront
  // (that will effectively log in the user in the default client and redirect to the home page of the default client)
  const { setUser } = useUser({
    redirectIfFound: {
      url: isAuthorizationRedirect ? `/api/oauth/authorize?${redirectParams}` : defaultStoreFrontLoginUri || `/user`
    }
  });

  const doHandleLogin = useLogin<LoginFormUser>();

  // The handler for the form submit
  const doLoginUser = useCallback(
    async (form: LoginFormUser, formikHelpers: FormikHelpers<LoginFormUser>) => {
      try {
        // Try to login the user
        const user = await doHandleLogin(form);

        // Update the user on state
        await setUser(user);
      } catch (error) {
        const errorMessage = await getLocaleIdFromFetchError(error);
        // Update the error state
        if (errorMessage) {
          formikHelpers.setFieldError('form', errorMessage);
        }
      }
    },
    [doHandleLogin, setUser]
  );

  return <LoginTemplate doLoginUser={doLoginUser} redirectParams={redirectParams} {...props} />;
};

export const getServerSideProps: GetServerSideProps<LoginContainerProps> = async (ctx) => {
  let props: LoginContainerProps = { query: ctx.query };
  try {
    const currentStoreFront = await findByClientId(env().NEXT_PUBLIC_CLIENT_ID);
    if (currentStoreFront?.googleTagManagerId) {
      props = {
        ...props,
        googleTagManagerId: currentStoreFront.googleTagManagerId
      };
    }

    const storeFronts = await findNonManagementClientsHomeUri();
    if (!storeFronts || storeFronts.length === 0)
      throw new Error(`Could not find Store Front homeUris. Found: "${storeFronts}"`);

    const defaultClient = storeFronts.find((client) => client.clientId === env().DEFAULT_CLIENT_ID);
    const californiaClient = storeFronts.find((client) => client.clientId === 'california-connect');

    if (defaultClient?.homeUri) {
      const defaultStoreFrontOrigin = new URL(defaultClient.homeUri).origin;
      const defaultStoreFrontLoginUri = new URL('/api/login', defaultStoreFrontOrigin);
      defaultStoreFrontLoginUri.searchParams.set('redirectTo', defaultStoreFrontOrigin);
      // Initialize the props with some default values
      props = {
        ...props,
        defaultStoreFrontLoginUri: defaultStoreFrontLoginUri.toString(),
        exportConnectHomeUri: defaultClient.homeUri
      };
    }
    if (californiaClient?.homeUri) {
      props = {
        ...props,
        caExportConnectHomeUri: californiaClient?.homeUri
      };
    }

    if (!defaultClient?.homeUri)
      throw new Error(`Could not find default client Store Front homeUri. Found: "${defaultClient}"`);
    if (!californiaClient?.homeUri)
      throw new Error(`Could not find California Store Front homeUri. Found: "${californiaClient}"`);
  } catch (err) {
    logging.error(err, `Failed to load props for [LoginContainer]`, {
      params: ctx.params,
      query: ctx.query
    });
  }

  return { props };
};

export default LoginContainer;
