import { ParsedUrlQuery } from 'querystring';

export const buildURLSearchParams = (query: ParsedUrlQuery) => {
  const searchParams = new URLSearchParams();
  Object.keys(query).map((name) => {
    if (typeof query[name] === 'string') searchParams.set(name, query[name] as string);
    else if (typeof query[name] !== 'undefined' && query[name]?.length) {
      // typeof query[name] value must be string[]
      (query[name] as string[]).map((value) => searchParams.append(name, value));
    }
  });
  return searchParams;
};

export const areAuthorizationParamsComplete = (query: ParsedUrlQuery) => {
  return (
    typeof query.client_id === 'string' && typeof query.redirect_uri === 'string' && typeof query.scope === 'string'
  );
};
